'use strict';

window.addEventListener('load',  () => {
    if(getCookie('coaching') === '1'){
        const banner2 = document.querySelector('.coaching-banner-2');
        if(banner2){
            banner2.style.display = 'none';
        } else return
    }  if(getCookie('coaching') === '2'){
        const banner1 = document.querySelector('.coaching-banner-1');
        if(banner1){
            banner1.style.display = 'none';
        } else return;
    }
})

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

(function () {
    if(!getCookie('coaching')){
        let random = Math.floor(Math.random() * 100) + 1;
        switch (true) {
            case random < 80: document.cookie = "coaching=2";
                break
            case random < 100: document.cookie = "coaching=1";
                break
            default: return 2
        }
    } else return
}());

